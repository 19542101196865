@font-face {
    font-family: 'Alpine New Alps';
    src: url('AlpineNewAlps-Regular.woff2') format('woff2'),
        url('AlpineNewAlps-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alpine New Alps';
    src: url('AlpineNewAlps-Semibold.woff2') format('woff2'),
        url('AlpineNewAlps-Semibold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alpine New Alps';
    src: url('AlpineNewAlps-Light.woff2') format('woff2'),
        url('AlpineNewAlps-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

